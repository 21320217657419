import * as React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const Button = ({
    type,
    variant,
    size,
    icon,
    isSubmitting,
    isSubmitted,
    className,
    children
}) => {
    return (
        <button
            className={`btn${variant ? ` btn-${variant}` : ' btn-primary'}${
                size && ` btn-${size}`
            }${icon && ` btn-${icon}`}${className && ` ${className}`}`}
            type={type}
            disabled={isSubmitting || isSubmitted}
        >
            {children}
        </button>
    )
}

Button.propTypes = {
    /**
     * Button type
     */
    type: PropTypes.string,
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['', 'secondary']),
    /**
     * Optional set alternate button size
     */
    size: PropTypes.oneOf(['', 'sm', 'lg']),
    /**
     * Optional set button icon
     */
    icon: PropTypes.oneOf(['', 'arrow', 'chevron', 'external']),
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool.isRequired,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

Button.defaultProps = {
    type: 'submit',
    variant: '',
    size: '',
    icon: '',
    isSubmitting: false,
    isSubmitted: false,
    className: '',
    children: 'Button title'
}

export default Button
